.drop-input {
  height: 15vh;
  background: #fff;
  margin: 10px;
  display: flex;
  align-items: center;
  border: 3px solid #000;
  border-style: dashed;
  position: relative;
  width: auto;
}

.containerDocs {
  display: flex;
  flex: 1;
  padding-top: 10px;
  flex-direction: row;

  @media only screen and (max-width: 700px) {
  display: flex;
  flex: 1;
  padding-top: 10px;
  flex-direction: column;
  }
}

.uploadView {
  position: fixed;
  width: 40vw;
  margin-top: 10px;

  @media only screen and (max-width: 700px) {
    position: unset;
    width: unset;
    display: 'flex';
    flex-direction: 'column';
    flex: 1;
  }

  @media only screen and (min-width: 1440px) {
    width: 30vw;
  }
}

.elenco-docs {
  margin-left: 40vw;

  @media only screen and (max-width: 700px) {
    margin-left: unset;
  }

  @media only screen and (min-width: 1440px) {
    margin-left: 30vw;
  }
}

#drawer {
  width: 100%;
  height: 80vh;

  @media only screen and (max-width: 700px) {
    width: 100%;
    height: 80vh;
  }
}

.box-qr-code {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
  padding: 5px;
  text-align: center;

  @media only screen and (max-width: 425px) {
    display: none;
  }
}

