.myForm {
  width: 300px;
  margin: 0 auto;
  padding-top: 20px;
  background: white !important;
}

@media (max-width: 500px) {
  .myForm {
    width: 90%;
    margin: 0 auto;
    margin-top: 20px;
  }
}
