.testvideo {
  width: 100%;
  height: 240px;
  flex: 1;
  object-fit: contain;
  
  @media (max-height: 320px) {
    width: 100%;
    height: 120px;
    flex: 1;
    object-fit: contain;
  }
}

.test-video {
  width: 320px;
  display: flex;
  flex-direction: column;

  @media (max-width: 425px) {
    width: 100%;
  }
}

.test-audio {
  display: flex;
  flex-direction: column;
}

.container-video {
  display: flex;
  flex-direction: row;

  @media (min-width: 320px) and (max-width: 480px) {
    display: flex;
    flex-direction: column;
  }

}

.container-player {
  position: relative
}