.dialog_content {
  display: 'flex';
  flex: 1;
  flex-direction: 'column';
  max-width: 500px;
  background: white;
}

@media only screen and (max-width: 700px) {
  .dialog_content {
    display: 'flex';
    flex: 1;
    flex-direction: 'column';
    max-width: 400px;
    background: white;
  }
}
