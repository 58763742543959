.exit_button {
  display: flex;
  width: 80px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

#header {
  height: 60px;
  background-color: #fff;
  padding: 0 14px 0 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999999;
}
.toolbar {
  padding: 0 !important;
}

.headerLight {
  color: #706969 !important;
  background-color: #eeeeee !important;
}

.navButtonRec {
  display: flex;
  flex: 1;
  align-items: center;
  color: #c71100;
}

.img_logo_rec {
  width: 30px;
  margin-right: 10px;
}

#navSessionInfo {
  position: absolute;
}

#navButtons {
  position: absolute;
  left: 0;
  right: 0;
}
#navChatButton {
  position: absolute;
  right: 10px;
  top: 5px;
}

#titleContent {
  max-width: 100px;
  background-color: #494949;
  margin: 5px -18px;
  padding: 0px 15px;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  float: right;
  margin-top: 20px;
}

.titleContentLight {
  background-color: #dfdfdf !important;
  color: #000;
}

#header_img {
  max-width: 135px;
  margin-right: 10px;
  margin-top: 10px;
}

#session-title {
  font-family: 'Open Sans', sans-serif;
}

#point {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 12px;
  right: 33px;
  border-radius: 50%;
  background-color: #ffa600;
  border: 1px solid #000;
  z-index: 99999;
}
.pointLight {
  border: 1px solid #ffffff !important;
}

#rightButton {
  float: right;
  margin-right: 5px;
}

#badge {
  top: 0 !important;
  flex-shrink: unset !important;
}

.buttonsContent {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
}

.buttonsLeft {
  height: 60px !important;
  display: flex;
  position: absolute;
  left: 0;
  z-index: 1301;
}

.buttonsEngineRight {
  height: 60px !important;
  display: flex;
  position: absolute;
  right: 0;
}

.MuiIconButton-root {
  border-radius: 0% !important;
}

@media only screen and (max-width: 700px) {
  .buttonsLeft {
    position: unset;
  }
  #titleContent,
  #navChatButton {
    display: none;
  }
  #misurazioni {
    display: none;
  }
  #documenti {
    display: none;
  }
  #text_rec {
    display: none;
  }
  #header {
    height: 50px;
    background-color: #fff;
    padding: 0 14px 0 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999999;
  }
  .toolbar {
    padding: 0 !important;
  }

  .headerLight {
    color: #706969 !important;
    background-color: #eeeeee !important;
  }

  .navButtonRec {
    display: flex;
    flex: 1;
    align-items: center;
    color: #c71100;
  }

  .img_logo_rec {
    width: 30px;
    margin-right: 10px;
    height: 30px;
  }

  #navSessionInfo {
    position: absolute;
  }

  #navButtons {
    position: absolute;
    left: 0;
    right: 0;
  }
  #navChatButton {
    position: absolute;
    right: 10px;
    top: 5px;
  }

  #titleContent {
    max-width: 100px;
    background-color: #494949;
    margin: 5px -18px;
    padding: 0px 15px;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    float: right;
    margin-top: 20px;
  }

  .titleContentLight {
    background-color: #dfdfdf !important;
    color: #000;
  }

  #header_img {
    max-width: 135px;
    margin-right: 10px;
    margin-top: 10px;
  }

  #session-title {
    font-family: 'Open Sans', sans-serif;
  }

  #point {
    width: 10px;
    height: 10px;
    position: absolute;
    top: 12px;
    right: 33px;
    border-radius: 50%;
    background-color: #ffa600;
    border: 1px solid #000;
    z-index: 99999;
  }
  .pointLight {
    border: 1px solid #ffffff !important;
  }

  #badge {
    top: 0 !important;
    flex-shrink: unset !important;
  }

  .buttonsLeft {
    height: 50px !important;
    display: flex;
    position: absolute;
    left: 0;
    z-index: 1301;
  }

  .MuiIconButton-root {
    border-radius: 0% !important;
  }
}
