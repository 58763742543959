
.container-share-drop {
  background-color: #494949;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: inherit;
  width: inherit;
}

.card-drop {
  margin: 10px auto 0 auto;
  width: 90%;

  @media only screen and (min-width: 700px) {
    width: 50vw;
  }
}