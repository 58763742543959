.toolbar {
  height: 60px;
  background-color: #fff;
  padding: 0 14px 0 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999999 !important;
}

.container {
  background-color: #494949;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: inherit;
  width: inherit;
}

.buttonsRight {
  height: 60px !important;
  display: flex;
  position: absolute;
  right: 0;
  width: 120px !important;
}

#rightButton {
  float: right;
  margin-right: 5px;
}

@media only screen and (max-width: 700px) {
  #header {
    height: 60px;
    background-color: #fff;
    padding: 0 14px 0 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999999 !important;
  }
  .toolbar {
    padding: 0 !important;
  }

  .container {
    background-color: #494949;
    overflow: hidden;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: inherit;
    width: inherit;
  }
}
