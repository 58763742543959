.nickname {
  padding: 3px !important;
  position: absolute;
  width: 100%;
  z-index: 999;
  color: #ffffff;
  bottom: 36px;
  height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.figura {
  background: rgb(255, 255, 255);
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  padding: 3px !important;
  position: absolute;
  bottom: 10px;
  width: 100%;
  left: 0;
  z-index: 999;
  color: #000000;
  height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.form-control {
  color: black;
}

.pointer {
  cursor: pointer;
}

#closeButton {
  position: absolute;
  top: -3px;
  right: 0;
  z-index: 999;
}

#name-error-text {
  color: #fd6d5f;
  font-weight: bold;
  text-align: center;
}

#nicknameForm {
  padding: 10px;
}
.fullscreen {
  top: 60px;
}

.streamComponent {
  height: 100%;
  background-color: #000000;
}

video {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
  color: #ffffff;
  margin: 0;
  padding: 0;
  border: 0;
}

#statusIcons {
  bottom: 0;
  width: 60px;
  height: fit-content;
  position: absolute;
  color: #ffffff;
  right: 10px;
}

#camIcon {
  text-align: center;
  width: 40px;
}
#micIcon {
  text-align: center;
}

#fullscreenButton {
  position: absolute;
  bottom: 1px;
  right: 1px;
  z-index: 1000;
  background-color: #000000c4;
}
#volumeButton {
  position: absolute;
  right: 10px;
  z-index: 1000;
  color: #ffffff;
  top: 10px;
  border-radius: 50% !important;
}

#statusButton {
  position: relative;
  z-index: 1000;
  color: #ffffff;
  bottom: 60px;
  margin: 10px;
  border-radius: 50% !important;
}
/* Contains the video element, used to fix video letter-boxing */
.OT_widget-container {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
}

#input,
#label {
  color: white;
}

.videoOffContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(48, 46, 46);
  background: -moz-radial-gradient(circle, rgba(48, 46, 46, 1) 14%, rgba(0, 0, 0, 1) 100%);
  background: -webkit-radial-gradient(circle, rgba(48, 46, 46, 1) 14%, rgba(0, 0, 0, 1) 100%);
  background: radial-gradient(circle, rgba(48, 46, 46, 1) 14%, rgba(0, 0, 0, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#302e2e",endColorstr="#000000",GradientType=1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.userVideoOff {
  border-radius: 50%;
  height: 100px;
  width: 100px;
  color: white;
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 50px;
  border: 1px solid white;
}
